import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";

import { AppRoutes } from "./routes/AppRoutes";
import { store } from "./store/store";

import "../styles/index.scss";

const App = () => (
  <Provider store={store}>
    <AppRoutes />
    {/* <iframe style={{ display: "none" }} src="http://localhost:8080/iframe" id="ifr-metadata"></iframe> */}
  </Provider>
);

render(<App />, document.getElementById("root"));
