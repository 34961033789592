import React from "react";
import { Link } from "react-router-dom";
import headerLogo from "@assets/images/logo/testother-logo-text.svg";

import { ROUTES } from "@routes/config";

export const HeaderLogo = () => (
  <Link to={ROUTES.ROOT} className="headerLogo">
    <img className="logo__img" src={headerLogo} alt="" />
  </Link>
);
