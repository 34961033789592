import { wait } from "@tools/wait";
import { BaseQueryApi } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from "@reduxjs/toolkit/dist/query/react";
import { RootState } from "@store/rootReducer";
import { ServerStatusCodes } from "@models/errors";

import { ResponseRefresh } from "./auth/auth.api";
import { logout, refreshToken } from "./auth/auth.slice";

const BASE_URL_ESIFUL = process.env.API_ESIFUL;
const BASE_URL_OAUTH = process.env.API_ADMIN;
const CLIENT_ID = process.env.CLIENT_ID;

const prepareHeaders = (headers: Headers, { getState }: Pick<BaseQueryApi, "getState">) => {
  const token = (getState() as RootState).authReducer.accessToken;
  if (token) {
    headers.set("Authorization", `Bearer ${token}`);
  }
  return headers;
};

const baseQueryOauth = fetchBaseQuery({
  baseUrl: BASE_URL_OAUTH,
  prepareHeaders,
});

const baseQueryEsiful = fetchBaseQuery({ baseUrl: BASE_URL_ESIFUL });

const baseQueryAuth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, WebApi, extraOptions) => {
  const state = WebApi.getState() as RootState;
  let result = await baseQueryOauth(args, WebApi, extraOptions);

  if (result.error?.status === ServerStatusCodes.UNAUTHORIZED) {
    const refreshResult = await refreshRequest(state, WebApi, extraOptions);
    if (refreshResult.data) {
      WebApi.dispatch(refreshToken(refreshResult.data as ResponseRefresh));
      result = await baseQueryOauth(args, WebApi, extraOptions);
    } else {
      WebApi.dispatch(logout());
    }
  }

  return result;
};

const refreshRequest: BaseQueryFn<RootState, unknown, FetchBaseQueryError> = async (state: RootState, WebApi, extraOptions) =>
  await fetchBaseQuery()(
    { url: `${BASE_URL_OAUTH}/token/refresh`, method: "POST", body: { clientId: CLIENT_ID, refreshToken: state.authReducer.refreshToken } },
    WebApi,
    extraOptions,
  );

const baseQueryEsifulWithRepeat: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, WebApi, extraOptions) => {
  let result = await baseQueryEsiful(args, WebApi, extraOptions);
  if (result.meta?.response?.status === 429) {
    await wait(100);
    result = await baseQueryEsiful(args, WebApi, extraOptions);
  }
  return result;
};

export const apiAuth = createApi({
  reducerPath: "authApi",
  baseQuery: baseQueryAuth,
  endpoints: () => ({}),
});

export const apiEsiful = createApi({
  reducerPath: "apiEsiful",
  baseQuery: baseQueryEsifulWithRepeat,
  endpoints: () => ({}),
});
