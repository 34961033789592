import jwt_decode from "jwt-decode";

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@store/rootReducer";
import { LocalStorageKeys, SessionStorageKeys } from "@models/keycloak";

import { AccessToken, AttributeCertificate, ResponseRefresh } from "./auth.api";

export enum Roles {
  MODERATOR = "moderator",
  READER = "reader",
  SPECTATOR = "spectator",
  WRITER = "writer",
}

export interface AuthState {
  accessToken: string | null;
  certificate: AttributeCertificate | null;
  email: string | null;
  idToken: string | null;
  isLoggedIn: boolean;
  name: string | null;
  phone: string | null;
  refreshToken: string | null;
  username: string | null;
}

const initialState: AuthState = {
  accessToken: null,
  refreshToken: null,
  idToken: null,
  name: null,
  email: null,
  phone: null,
  isLoggedIn: false,
  username: null,
  certificate: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setEmail: (state, { payload }: PayloadAction<string>) => {
      state.email = payload;
    },
    loginSuccess: (state, { payload: { access_token, refresh_token, fio } }: PayloadAction<{ access_token: string; fio: string; refresh_token: string }>) => {
      localStorage.setItem(LocalStorageKeys.ACCESS_TOKEN, access_token);
      localStorage.setItem(LocalStorageKeys.REFRESH_TOKEN, refresh_token);
      state.accessToken = access_token;
      state.refreshToken = refresh_token;
      const token: AccessToken = jwt_decode(access_token);
      state.name = fio;
      state.isLoggedIn = true;
      state.email = token.email;
      state.username = token.preferred_username;
    },
    refreshToken: (state, { payload: { accessToken, refreshToken } }: PayloadAction<ResponseRefresh>) => {
      localStorage.setItem(LocalStorageKeys.ACCESS_TOKEN, accessToken);
      localStorage.setItem(LocalStorageKeys.REFRESH_TOKEN, refreshToken);
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
    },
    logout: () => {
      Object.values(LocalStorageKeys).forEach((key) => localStorage.removeItem(key));
      Object.values(SessionStorageKeys).forEach((key) => sessionStorage.removeItem(key));
      return initialState;
    },
  },
});

export const { loginSuccess } = authSlice.actions;
export const { logout } = authSlice.actions;
export const { setEmail } = authSlice.actions;
export const { refreshToken } = authSlice.actions;

export const authReducer = authSlice.reducer;

export const getUserEmail = (state: RootState) => state.authReducer.email;
export const getAuthReducer = (state: RootState) => state.authReducer;
