import React from "react";

import { AccountMenu } from "./AccountMenu";
import { HeaderLogo } from "./HeaderLogo";
import { Sections } from "./Sections";

export const Header = () => (
  <>
    <header>
      <div className="wrapper">
        <section className="header">
          <HeaderLogo />
          <Sections />
          <AccountMenu />
        </section>
      </div>
    </header>
    <div className="separator" />
  </>
);
