import { authReducer } from "@redux/auth/auth.slice";
import { apiAuth, apiEsiful } from "@redux/baseQuery";
import { apiKpsis } from "@redux/kpsis/kpsis.api";
import { toastReducer } from "@redux/toast/toast.slice";
import { combineReducers } from "@reduxjs/toolkit";

export const rootReducer = combineReducers({
  [apiAuth.reducerPath]: apiAuth.reducer,
  [apiEsiful.reducerPath]: apiEsiful.reducer,
  [apiKpsis.reducerPath]: apiKpsis.reducer,
  authReducer,
  toastReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
