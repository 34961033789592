import React from "react";
import { Outlet } from "react-router-dom";

import { useCheckAuth } from "@hooks/auth/useCheckAuth";

import { Header } from "./Header";

export const MainLayout = () => {
  useCheckAuth();
  // console.log();

  return (
    <div className="mainLayout">
      <Header />
      <div className="outlet">
        <Outlet />
      </div>
      {/* <Footer /> */}
    </div>
  );
};
