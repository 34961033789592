import { ElementType } from "react";
import { AiOutlineCheck, AiOutlineClose, AiOutlineEye, AiOutlineEyeInvisible, AiOutlineMinus, AiOutlinePlus, AiOutlineStar, AiOutlineUsb } from "react-icons/ai";
import { BiBookBookmark, BiDownArrowAlt, BiDownload, BiGridAlt, BiIdCard, BiLeftArrowAlt, BiRightArrowAlt, BiUpArrowAlt } from "react-icons/bi";
import { BsClockHistory, BsDot, BsExclamationCircle, BsKey, BsThreeDots } from "react-icons/bs";
import { FaRegUser, FaSpinner, FaUserClock } from "react-icons/fa";
import { FiCheckCircle, FiChevronDown, FiChevronLeft, FiChevronRight, FiChevronsLeft, FiChevronsRight, FiChevronUp, FiSearch, FiUserCheck, FiUserPlus } from "react-icons/fi";
import { HiOutlineBell, HiOutlineServer } from "react-icons/hi";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { IoMailUnreadOutline, IoWarningOutline } from "react-icons/io5";
import { MdAdd, MdFormatLineSpacing, MdOutlineAccountTree, MdOutlineDelete } from "react-icons/md";
import { RiBookLine, RiUserStarLine } from "react-icons/ri";
import { TbClipboard, TbPencil, TbQuestionMark, TbSitemap, TbUsers } from "react-icons/tb";

export enum IconTypes {
  ADD = "add",
  ARROW_DOWN = "arrowDown",
  ARROW_LEFT = "arrowLeft",
  ARROW_RIGHT = "arrowRight",
  ARROW_UP = "arrowUp",
  BELL = "bell",
  BOOK = "book",
  BOOKMARK = "bookmark",
  CHECK = "check",
  CHEVRONS_LEFT = "chevronsLeft",
  CHEVRONS_RIGHT = "chevronsRight",
  CHEVRON_DOWN = "chevronDown",
  CHEVRON_LEFT = "chevronLeft",
  CHEVRON_RIGHT = "chevronRight",
  CHEVRON_UP = "chevronUp",
  CLIPBOARD = "clipboard",
  CLOCK_HISTORY = "clockHistory",
  CLOSE = "close",
  CLOSE_CIRCLE = "closeCircle",
  DELETE = "delete",
  DOT = "dot",
  DOWNLOAD = "download",
  EDIT_PENCIL = "editPencil",
  EYE = "eye",
  GRID = "grid",
  ID_CARD = "idCard",
  INFO = "info",
  INVISIBLE_EYE = "invisibleEye",
  KEY = "key",
  LINE_SPACING = "lineSpacing",
  MINUS = "minus",
  OUTLINE_CHECK = "outlineCheck",
  PLUS = "plus",
  QUESTION = "question",
  SEARCH = "search",
  SERVER = "server",
  SITEMAP = "sitemap",
  SPINNER = "spinner",
  STAR = "star",
  THREE_DOTS = "threeDots",
  TREE = "tree",
  UNREAD_MAIL = "unreadMail",
  USB_KEY = "usbKey",
  USER = "user",
  USERS = "users",
  USER_CHECK = "userCheck",
  USER_CLOCK = "userClock",
  USER_PLUS = "userPlus",
  USER_STAR = "userStar",
  WARNING = "warning",
}

export const IconTypeMap: { [key in IconTypes]: ElementType } = {
  [IconTypes.ARROW_DOWN]: BiDownArrowAlt,
  [IconTypes.ARROW_UP]: BiUpArrowAlt,
  [IconTypes.ARROW_LEFT]: BiLeftArrowAlt,
  [IconTypes.ARROW_RIGHT]: BiRightArrowAlt,
  [IconTypes.BELL]: HiOutlineBell,
  [IconTypes.BOOK]: RiBookLine,
  [IconTypes.BOOKMARK]: BiBookBookmark,
  [IconTypes.CHECK]: FiCheckCircle,
  [IconTypes.OUTLINE_CHECK]: AiOutlineCheck,
  [IconTypes.CHEVRON_LEFT]: FiChevronLeft,
  [IconTypes.CHEVRONS_LEFT]: FiChevronsLeft,
  [IconTypes.CHEVRON_RIGHT]: FiChevronRight,
  [IconTypes.CHEVRONS_RIGHT]: FiChevronsRight,
  [IconTypes.CHEVRON_UP]: FiChevronUp,
  [IconTypes.CHEVRON_DOWN]: FiChevronDown,
  [IconTypes.CLOSE]: AiOutlineClose,
  [IconTypes.CLOSE_CIRCLE]: IoMdCloseCircleOutline,
  [IconTypes.CLIPBOARD]: TbClipboard,
  [IconTypes.DOWNLOAD]: BiDownload,
  [IconTypes.DELETE]: MdOutlineDelete,
  [IconTypes.EYE]: AiOutlineEye,
  [IconTypes.GRID]: BiGridAlt,
  [IconTypes.INVISIBLE_EYE]: AiOutlineEyeInvisible,
  [IconTypes.INFO]: BsExclamationCircle,
  [IconTypes.LINE_SPACING]: MdFormatLineSpacing,
  [IconTypes.PLUS]: AiOutlinePlus,
  [IconTypes.MINUS]: AiOutlineMinus,
  [IconTypes.SEARCH]: FiSearch,
  [IconTypes.SERVER]: HiOutlineServer,
  [IconTypes.SITEMAP]: TbSitemap,
  [IconTypes.THREE_DOTS]: BsThreeDots,
  [IconTypes.TREE]: MdOutlineAccountTree,
  [IconTypes.USER]: FaRegUser,
  [IconTypes.USERS]: TbUsers,
  [IconTypes.ADD]: MdAdd,
  [IconTypes.SPINNER]: FaSpinner,
  [IconTypes.EDIT_PENCIL]: TbPencil,
  [IconTypes.KEY]: BsKey,
  [IconTypes.DOT]: BsDot,
  [IconTypes.USB_KEY]: AiOutlineUsb,
  [IconTypes.ID_CARD]: BiIdCard,
  [IconTypes.STAR]: AiOutlineStar,
  [IconTypes.CLOCK_HISTORY]: BsClockHistory,
  [IconTypes.UNREAD_MAIL]: IoMailUnreadOutline,
  [IconTypes.USER_STAR]: RiUserStarLine,
  [IconTypes.USER_CLOCK]: FaUserClock,
  [IconTypes.USER_CHECK]: FiUserCheck,
  [IconTypes.USER_PLUS]: FiUserPlus,
  [IconTypes.WARNING]: IoWarningOutline,
  [IconTypes.QUESTION]: TbQuestionMark,
};
