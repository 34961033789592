import { apiAuth } from "@redux/baseQuery";
import { mapKeycloakClients, Realms } from "@models/keycloak";

import { Roles } from "./auth.slice";

export interface ResponseLoginEsiful {
  code_verifier: string;
  enveloped_and_signed_auth_url: string;
  signed_data_to_check_in_cp: string;
}

export interface User {
  email: string | null;
  emailVerified: boolean;
  enabled: boolean;
  fio: string;
  id: string;
  isNew: true | null;
  personalNumber: string;
  phone: string | null;
  username: string;
  work: AttributeCertificate | null;
}

export interface ResponseLoginCallback {
  access_token: string;
  idToken: string;
  refresh_token: string;
  user: User;
}

export interface LogoutParams {
  authority: string;
  id_token: string;
  post_logout_redirect_uri: string;
}

export interface ResponseLogout {
  logout_url: string;
}

export interface AccessToken {
  email: string;
  exp: number;
  given_name: string;
  name: string;
  preferred_username: string;
  realm_access: {
    roles: Roles[];
  };
}

export interface AttributeCertificate {
  name: string;
  position?: string;
  unitName?: string;
  unp: string;
}

export interface ResponseRefresh {
  accessToken: string;
  refreshToken: string;
}

export interface Companions {
  fioList: string[];
}

export interface ResponseRole {
  description: string;
  id: string;
  name: string;
}

export interface AvailabelIsir {
  id: string;
  name: string;
}

export interface QueryParams {
  search: string;
  searchParam: SearhParams;
}

export enum SearhParams {
  EMAIL = "email",
  USERNAME = "username",
}

export interface Realm {
  display: string;
  id: string;
  redirectUrl: string;
}

const CLIENT_ID = process.env.CLIENT_ID;
// const SETTINGS_KPSIS = {
//   client_id: process.env.KPSIS_CLIENT_ID,
//   scope: process.env.KPSIS_SCOPE,
//   post_logout_redirect_uri: process.env.KPSIS_POST_LOGOUT_REDIRECT_URL,
//   redirect_uri: process.env.KPSIS_REDIRECT_URL,
// };

export const authApi = apiAuth.injectEndpoints({
  endpoints: (build) => ({
    // loginEsiful: build.mutation<ResponseLoginEsiful, string>({
    //   query: (realm) => ({
    //     url: `${realm}/login`,
    //     method: "POST",
    //     body: {
    //       clientId: mapKeycloakClients[realm as Realms],
    //       settings: SETTINGS_KPSIS,
    //     },
    //     credentials: "include",
    //   }),
    // }),
    // registrationEsiful: build.mutation<ResponseLoginCallback, { data: string; realm: string }>({
    //   query: ({ data, realm }) => ({
    //     url: `${realm}/registration`,
    //     method: "POST",
    //     body: {
    //       data,
    //       clientId: mapKeycloakClients[realm as Realms],
    //       settings: SETTINGS_KPSIS,
    //     },
    //     credentials: "include",
    //   }),
    // }),
    // loginCallback: build.mutation<ResponseLoginCallback, { data: string; realm: string }>({
    //   query: ({ data, realm }) => ({
    //     url: `${realm}/login_callback`,
    //     method: "POST",
    //     body: {
    //       data,
    //       clientId: mapKeycloakClients[realm as Realms],
    //       settings: SETTINGS_KPSIS,
    //     },
    //     credentials: "include",
    //   }),
    // }),
    logout: build.mutation<ResponseLogout | void, { idToken: string; realm: string }>({
      query: ({ idToken, realm }) => ({
        url: `${realm}/logout`,
        method: "POST",
        body: {
          idToken,
        },
      }),
    }),
    generateOTP: build.mutation<null, { email: string; realm: string }>({
      query: ({ realm, email }) => ({
        url: `${realm}/otp`,
        method: "POST",
        body: {
          clientId: mapKeycloakClients[realm as Realms],
          email,
        },
      }),
    }),
    validateOTP: build.mutation<ResponseLoginCallback, { email: string; otp: string; realm: string }>({
      query: ({ email, otp, realm }) => ({
        url: `${realm}/otp/validate`,
        method: "POST",
        body: {
          clientId: mapKeycloakClients[realm as Realms],
          email,
          otpNum: otp,
        },
      }),
    }),
    refreshAccessToken: build.mutation<ResponseRefresh, string>({
      query: (refresh_token: string) => ({
        url: "/token/refresh",
        method: "POST",
        body: {
          clientId: CLIENT_ID,
          refreshToken: refresh_token,
        },
      }),
    }),
    updateUserProfile: build.mutation<User, { email?: string; emailVerified?: string; fio?: string; phone?: string }>({
      query: (body) => ({
        url: "/profile",
        method: "PUT",
        body,
      }),
    }),
    getUserByUsernameOrEmail: build.query<User, QueryParams[] | null>({
      query: (searchArray) => {
        const params = searchArray?.reduce((obj, { searchParam, search }) => ({ ...obj, [searchParam]: search }), {});
        return {
          url: "/user",
          params,
        };
      },
    }),
    getCompanion: build.mutation<Companions, string>({
      query: (usernames) => ({
        url: "/user/companion",
        method: "POST",
        body: {
          usernames,
        },
      }),
    }),
    // getUserRoles: build.query<ResponseRole[], string>({
    //   query: (username) => ({
    //     url: `/user/${username}/roles`,
    //   }),
    // }),
    // getRolesAccessRights: build.query<AvailabelIsir[], string>({
    //   query: (roleName) => ({
    //     url: `/role/${roleName}/accessRights`,
    //   }),
    // }),
    getAllRealms: build.query<Realm[], void>({
      query: () => ({
        url: "/realm",
      }),
    }),
    exchangeToken: build.query<{ access_token: string; refresh_token: string; user: User }, { clientId: string; realmName: string }>({
      query: (body) => ({
        url: "/token/exchange",
        method: "POST",
        body,
      }),
    }),
  }),
});

// export const { useGetUserStatsQuery } = authApi;
// export const { useLoginEsifulMutation } = authApi;
// export const { useRegistrationEsifulMutation } = authApi;
// export const { useLoginCallbackMutation } = authApi;
export const { useGenerateOTPMutation } = authApi;
export const { useValidateOTPMutation } = authApi;
export const { useLogoutMutation } = authApi;
export const { useRefreshAccessTokenMutation } = authApi;
export const { useGetCompanionMutation } = authApi;
export const { useUpdateUserProfileMutation } = authApi;
export const { useGetUserByUsernameOrEmailQuery } = authApi;
export const { useLazyGetUserByUsernameOrEmailQuery } = authApi;
// export const { useLazyGetUserRolesQuery } = authApi;
// export const { useLazyGetRolesAccessRightsQuery } = authApi;
export const { useGetAllRealmsQuery } = authApi;
export const { useLazyExchangeTokenQuery } = authApi;
