import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const apiKpsis = createApi({
  reducerPath: "apiKpsis",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.API_KPSIS,
  }),
  endpoints: (build) => ({
    decrypt: build.mutation<{ cms: string; error: string }, string>({
      query: (data) => ({
        url: "/decryptcms",
        method: "POST",
        body: {
          data,
        },
      }),
    }),
  }),
});

export const { useDecryptMutation } = apiKpsis;
