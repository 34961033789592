import React from "react";
import authorizedImage from "@assets/images/mainPage/authorized.jpg";
import notAuthorizedImage from "@assets/images/mainPage/notAuthorized.jpg";

import { useAppSelector } from "@hooks/redux";
import { getAuthReducer } from "@redux/auth/auth.slice";

export const postCrossDomainMessageMetadata = (msg: { key: string; value: string }) => {
  const win = (document.getElementById("ifr-metadata") as HTMLIFrameElement).contentWindow;
  win?.postMessage(msg, "http://localhost:8080/");
};

export const Profile = () => {
  const { isLoggedIn, name } = useAppSelector(getAuthReducer);

  return (
    <div className="profile wrapper">
      <h1>Главная страница</h1>
      <div>{isLoggedIn ? <div>Пользователь {name} авторизован</div> : <div>Пользователь не авторизован</div>}</div>
      <img style={{ display: "block", margin: "50px auto" }} src={isLoggedIn ? authorizedImage : notAuthorizedImage} alt="" />
    </div>
  );
};
