import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { Toast } from "@components/Toast";
import { useLogout } from "@hooks/auth/useLogout";
import { useAppDispatch, useAppSelector } from "@hooks/redux";
import { getAuthReducer } from "@redux/auth/auth.slice";
import { deleteToast } from "@redux/toast/toast.slice";
import { ROUTES } from "@routes/config";
import { AUTH_SUCCESS_MESSAGE, navigateToLogin } from "@views/Login";

import { DropDown, DropDownTypes } from "../DropDown";
import { Icon, IconSize } from "../Icon/Icon";
import { IconTypes } from "../Icon/IconsLib";

export const AccountMenu = () => {
  const dispatch = useAppDispatch();
  // const navigate = useNavigate();
  const location = useLocation();

  const { isLoggedIn, name } = useAppSelector(getAuthReducer);

  const [isShowDropDown, setIsShowDropDown] = useState(false);

  const toasts = useAppSelector(({ toastReducer }) => toastReducer.toasts);
  const mapToasts = toasts.map((toast) => <Toast key={toast.id} toast={toast} />);

  const { logoutRequest } = useLogout();

  useEffect(() => {
    if (location.pathname !== ROUTES.ROOT) {
      toasts.map((toast) => toast.message === AUTH_SUCCESS_MESSAGE && dispatch(deleteToast(toast.id)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const handleClick = () => toggleDropDown();
  const handleBlur = () => setIsShowDropDown(false);
  const toggleDropDown = () => setIsShowDropDown((prev) => !prev);

  const dropDownItems = [{ text: "Войти", onClick: navigateToLogin }];

  const dropDownItemsWithAuth = [
    {
      text: "Выйти",
      onClick: logoutRequest,
    },
  ];

  return (
    <div className="accountMenu">
      {isLoggedIn ? (
        <Link className="iconWrapper" to={ROUTES.ROOT}>
          <Icon icon={IconTypes.USER} size={IconSize.SMALL} className="userRoundIcon" />
        </Link>
      ) : (
        <div className="iconWrapper">
          <Icon icon={IconTypes.USER} size={IconSize.SMALL} className="userRoundIcon" />
        </div>
      )}
      <div className="accountMenu__text">
        {isLoggedIn ? (
          name?.split(" ")[1]
        ) : (
          <div onClick={navigateToLogin} className="accountMenu__link">
            Вход в аккаунт
          </div>
        )}
      </div>
      <div className="upperArrowIcon" onClick={handleClick}>
        {isShowDropDown ? <Icon icon={IconTypes.CHEVRON_UP} size={IconSize.SMALL} /> : <Icon icon={IconTypes.CHEVRON_DOWN} size={IconSize.SMALL} />}
        {isShowDropDown && <DropDown type={DropDownTypes.MENU} items={isLoggedIn ? dropDownItemsWithAuth : dropDownItems} onBlur={handleBlur} />}
      </div>
      <div className="toasts__container">{mapToasts}</div>
    </div>
  );
};
