import { get } from "lodash";

export enum ServerStatusCodes {
  OK = 200,

  CREATED = 201,

  NO_CONTENT = 204,

  BAD_REQUEST = 400,
  NOT_ADD_USER = 400001,
  BAD_REQUEST_FEIGN = 400002,
  NOT_ADD_USER_TO_GROUP = 400003,
  NOT_ADD_TO_IDENTITY_PROVIDER = 400004,
  FIELDS_NOT_VALID = 400005,
  WRONG_FIELD = 400006,
  HASH_ALGORITHM = 400007,

  UNAUTHORIZED = 401,

  NOT_FOUND = 404,
  ENTITY_NOT_FOUND = 404001,
  USER_NOT_FOUND = 40400001,

  CONFLICT = 409,
  ENTITY_ALREADY_EXISTS = 409001,
  USER_ALREADY_LINKED_EXISTS = 409002,

  // --- 5xx Server Error ---

  SERVER_ERROR = 500,

  SERVICE_UNAVAILABLE = 503,
  SERVICE_TIMEOUT_ERROR = 503001,
  SERVICE_NOT_ACTIVE = 503002,
  FETCH_ERROR_STATUS = "FETCH_ERROR",
}

export const ServerMessages: Record<ServerStatusCodes, string> = {
  [ServerStatusCodes.OK]: "ОК",
  [ServerStatusCodes.CREATED]: "Создано",
  [ServerStatusCodes.NO_CONTENT]: "Нет содержимого",
  [ServerStatusCodes.BAD_REQUEST]: "Неверный запрос",
  [ServerStatusCodes.NOT_ADD_USER]: "Пользователь не был добавлен",
  [ServerStatusCodes.BAD_REQUEST_FEIGN]: "Ошибка запроса во время Feign. Ответ сервиса - %s",
  [ServerStatusCodes.NOT_ADD_USER_TO_GROUP]: "Пользователь не был добавлен в группу - %s",
  [ServerStatusCodes.NOT_ADD_TO_IDENTITY_PROVIDER]: "Пользователь не был добавлен в поставщик идентификации",
  [ServerStatusCodes.FIELDS_NOT_VALID]: "Недопустимые поля сущности",
  [ServerStatusCodes.WRONG_FIELD]: "",
  [ServerStatusCodes.HASH_ALGORITHM]: "Криптографический алгоритм недоступен в данной среде",
  [ServerStatusCodes.UNAUTHORIZED]: "Не авторизован",
  [ServerStatusCodes.NOT_FOUND]: "Не найдено - %s",
  [ServerStatusCodes.ENTITY_NOT_FOUND]: "Сущность не найдена",
  [ServerStatusCodes.USER_NOT_FOUND]: "Такого пользователя не существует",
  [ServerStatusCodes.CONFLICT]: "Конфликт",
  [ServerStatusCodes.ENTITY_ALREADY_EXISTS]: "Сущность уже существует",
  [ServerStatusCodes.USER_ALREADY_LINKED_EXISTS]: "Пользователь уже связан с провайдером",
  [ServerStatusCodes.SERVER_ERROR]: "Ошибка сервера! Свяжитесь с администратором.",
  [ServerStatusCodes.SERVICE_UNAVAILABLE]: "Сервис недоступен",
  [ServerStatusCodes.SERVICE_TIMEOUT_ERROR]: "Ошибка тайм-аута сервиса",
  [ServerStatusCodes.SERVICE_NOT_ACTIVE]: "Сервис неактивен",
  // eslint-disable-next-line quotes
  [ServerStatusCodes.FETCH_ERROR_STATUS]: 'У Вас не запущена клиентская программа "NTClientSoftware"!',
};

export interface ServerError {
  data?: { error: string; errors: string; message: string; status?: ServerStatusCodes };
  error?: string;
  message?: string;
  status?: string;
}

const DEFAULT_ERROR_TOAST_TEXT = "Что-то пошло не так... :(";

export const getServerErrorMessage = (error: ServerError) =>
  error?.error || error?.data?.error || error?.data?.message || error?.data?.errors || error?.message || DEFAULT_ERROR_TOAST_TEXT;

export enum StatusPaths {
  CP_PATH = "status",
  SERVER_PATH = "data.status",
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getErrorMessage = (error: any, statusPath = StatusPaths.SERVER_PATH) => {
  // TODO: any to ServerError
  if (error) {
    const status = get(error, statusPath, "") as ServerStatusCodes;

    const message = status ? ServerMessages[status] : "";

    if (message) {
      return message;
    }
  }

  return getServerErrorMessage(error);
};
