import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { Loader } from "@components/Loader";
import { MainLayout } from "@components/MainLayout";
import { Page404 } from "@views/ErrorsPage/Page404";
import { Profile } from "@views/Profile/Profile";

import { ROUTES } from "./config";

export const AppRoutes = () => (
  <BrowserRouter>
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path={ROUTES.ALL} element={<Page404 />} />
        <Route element={<MainLayout />}>
          <Route index element={<Profile />} />
        </Route>
      </Routes>
    </Suspense>
  </BrowserRouter>
);
