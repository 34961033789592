import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import jwt_decode from "jwt-decode";
import moment from "moment";

import { base64ToString } from "@tools/base64ToString";
import { useAppDispatch } from "@hooks/redux";
import { AccessToken, useRefreshAccessTokenMutation } from "@redux/auth/auth.api";
import { loginSuccess, logout } from "@redux/auth/auth.slice";
import { useDecryptMutation } from "@redux/kpsis/kpsis.api";
import { LocalStorageKeys } from "@models/keycloak";

export const useCheckAuth = () => {
  const dispatch = useAppDispatch();
  const [refresh] = useRefreshAccessTokenMutation();
  const [decrypt] = useDecryptMutation();
  const [isQuery, setIsQuery] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(
    () => {
      const accessToken = searchParams.get("accessToken") || localStorage.getItem(LocalStorageKeys.ACCESS_TOKEN);
      const refreshToken = searchParams.get("refreshToken") || localStorage.getItem(LocalStorageKeys.REFRESH_TOKEN);
      if (accessToken && refreshToken) {
        const { exp, given_name }: AccessToken = jwt_decode(accessToken);
        const isValidToken = moment(exp * 1000).isAfter(new Date());
        if (isValidToken) {
          decrypt(given_name)
            .unwrap()
            .then(({ cms }) => {
              const fio = base64ToString(cms);
              dispatch(loginSuccess({ access_token: accessToken, refresh_token: refreshToken, fio }));
              if (searchParams.get("accessToken")) {
                const currentParams = Object.fromEntries([...searchParams]);
                delete currentParams.accessToken;
                delete currentParams.refreshToken;
                setSearchParams({ ...currentParams }, { replace: true });
              }
            });
        } else {
          if (!isQuery) {
            setIsQuery(true);
            refresh(refreshToken)
              .unwrap()
              .then(({ accessToken, refreshToken }) => {
                decrypt(given_name)
                  .unwrap()
                  .then(({ cms }) => {
                    const fio = base64ToString(cms);
                    dispatch(loginSuccess({ access_token: accessToken, refresh_token: refreshToken, fio }));
                    if (searchParams.get("accessToken")) {
                      const currentParams = Object.fromEntries([...searchParams]);
                      delete currentParams.accessToken;
                      delete currentParams.refreshToken;
                      setSearchParams({ ...currentParams }, { replace: true });
                    }
                  });
              })
              .catch(() => {
                dispatch(logout());
              })
              .finally(() => setIsQuery(false));
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
};
