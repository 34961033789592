import { authApi } from "@redux/auth/auth.api";
import { apiEsiful } from "@redux/baseQuery";
import { apiKpsis } from "@redux/kpsis/kpsis.api";
import { configureStore } from "@reduxjs/toolkit";

import { rootReducer } from "./rootReducer";

const middlewares = [authApi.middleware, apiEsiful.middleware, apiKpsis.middleware];

const store = configureStore({
  devTools: process.env.NODE_ENV !== "production",
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(middlewares),
});

export { store };

export type AppStore = typeof store;
export type AppDispatch = AppStore["dispatch"];
