import React from "react";
import cn from "classnames";

import { Button, Variants } from "@components/Button";
import { TypeToast } from "@components/Toast";
import { createToast } from "@redux/toast/toast.slice";
import { getErrorMessage, ServerError } from "@models/errors";

export type OutletContextType = (data: { accessToken: string; refreshToken: string }) => void;

export const handleAuthEsifulError = (e: ServerError) => {
  const errorMessage = getErrorMessage(e);
  return createToast(`Ошибка аутентификации ${errorMessage || ""}`, TypeToast.ERROR);
};

export enum RegistrationParams {
  CLAIM = "claim",
  OTP = "otp",
  USER_REQUEST = "userRequest",
}

export const AUTH_SUCCESS_MESSAGE = "Успешная аутентификация";
export const REGISTRATION_SUCCESS_MESSAGE = "Успешная регистрация";

export const navigateToLogin = () => {
  const LOGIN_URL = process.env.LOGIN_URL;
  const REALM = process.env.REALM || "";
  const REDIRECT_URL = window.location.href;
  const params = new URLSearchParams({ realm: REALM, redirectUrl: REDIRECT_URL });
  window.location.href = `${LOGIN_URL}?${params}`;
};

export const Login = () => (
  <div className="login wrapper">
    <Button variant={Variants.PRIMARY} className={cn("login__button", "button-esiful")} text="Вход" onClick={navigateToLogin} />

    <div className="brief">
      <p className="brief__p">Для прохождения авторизации при помощи ЕС ИФЮЛ на Вашем ПЭВМ должны быть установлены программы и устройства в следующих сочетаниях:</p>
      <ul className="brief__list">
        <li className="brief__list_item">
          для авторизации с помощью идентификационной карты (ID-карта):
          <ul className="brief__list">
            <li className="brief__list_item">клиентская программа BY.БФИД.10244-01;</li>
            <li className="brief__list_item">ID-карта;</li>
            <li className="brief__list_item">универсальный считыватель.</li>
          </ul>
        </li>
        <li className="brief__list_item">
          для авторизации на ПЭВМ (операционная система Windows) с помощью носителя (USB-устройства):
          <ul className="brief__list">
            <li className="brief__list_item">клиентская программа BY.БФИД.10244-01;</li>
            <li className="brief__list_item">программа криптопровайдера (NTCrypto БФИД.10186-01 или Avest CSP BIGN РБ.ЮСКИ.12005-02 «AvPKISetup2.exe»);</li>
            <li className="brief__list_item">носитель (USB-устройство) с импортированным личным сертификатом ГосСУОК.</li>
          </ul>
        </li>
      </ul>
      <p className="brief__p">
        Предоставление и использование программного обеспечения (клиентская программа и программа криптопровайдера) осуществляется в соответствии с условиями и соглашениями{" "}
        <a className="login__link" href="https://nces.by/pki/">
          оператора ГосСУОК
        </a>
        .
      </p>
    </div>
  </div>
);
