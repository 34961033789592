import { logout } from "@redux/auth/auth.slice";

import { useAppDispatch } from "../redux";

export const useLogout = () => {
  const dispatch = useAppDispatch();
  const logoutRequest = async () => {
    // const res = await logoutQuery({ realm: Realms.SSO, idToken: idToken || "" }).unwrap();
    // if (res?.logout_url) {
    //   window.location.replace(res.logout_url);
    // }
    dispatch(logout());
  };

  return { logoutRequest };
};
