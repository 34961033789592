export enum Realms {
  METADATA = "metadata.by",
  REPORTS = "own.agsr.by",
  SSO = "profile.agsr.by",
}

export const mapKeycloakClients: Record<Realms, string> = {
  [Realms.METADATA]: "main-web-client",
  [Realms.REPORTS]: "own-main-web-client",
  [Realms.SSO]: "profile-agsr-client",
};

export enum LocalStorageKeys {
  ACCESS_TOKEN = "accessToken",
  ID_TOKEN = "idToken",
  REFRESH_TOKEN = "refreshToken",
  USER = "user",
}

export enum SessionStorageKeys {
  EMAIL = "email",
  LOGIN_HANDLER_TYPE = "loginHandlerType",
  OTP = "otp",
  PREVIOUS_LOCATION = "previousLocation",
  REALM = "realm",
  REDIRECT_URL = "redirectUrl",
}
