import React from "react";
import { Link } from "react-router-dom";
import error404 from "@assets/images/errorsPage/error404.svg";

export const Page404 = () => (
  <main className="error-page">
    <section className="section">
      <div className="container">
        <img className="error-page__img" src={error404} alt="error 404" />
        <div className="container">
          <h1 className="error-page__title">Страница не найдена</h1>
          <p className="error-page__description">Страница, на которую вы пытаетесь попасть, не существует или была удалена.</p>
          <Link to="/" className="error-page__link">
            Перейдите на Главную страницу
          </Link>
        </div>
      </div>
    </section>
  </main>
);
