import { v4 as uuid } from "uuid";

import { Toast, TypeToast } from "@components/Toast";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ToastState {
  toasts: Array<Toast>;
}

const initialState: ToastState = {
  toasts: [],
};

export const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    addToast: (state, action: PayloadAction<Toast>) => {
      const errorToastDuplicate = state.toasts.find(({ message, type }) => type === TypeToast.ERROR && message === action.payload.message);
      if (!errorToastDuplicate) {
        state.toasts = [action.payload, ...state.toasts];
      }
    },
    deleteToast: (state, action: PayloadAction<string>) => {
      state.toasts = state.toasts.filter(({ id }) => id !== action.payload);
    },
  },
});

export const createToast = (message: string, type: TypeToast): Toast => {
  const id = uuid();
  return {
    id,
    message,
    type,
  };
};

export const { addToast, deleteToast } = toastSlice.actions;
export const toastReducer = toastSlice.reducer;
