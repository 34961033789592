import React, { FC, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";

interface DropDownItemWithOnClick {
  icon?: JSX.Element;
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  text: string;
}

interface DropDownItemWithLink {
  icon?: JSX.Element;
  link: string;
  text: string;
}

export type DropdownItemType = DropDownItemWithOnClick | DropDownItemWithLink;

export enum DropDownTypes {
  MENU = "menu",
  NOTIFICATION = "notification",
}

interface DropDownProps {
  className?: string;
  items: DropdownItemType[];
  onBlur?: () => void;
  type: DropDownTypes;
}

const dropDownWrapperClass: Record<DropDownTypes, string> = {
  [DropDownTypes.MENU]: "dropDown",
  [DropDownTypes.NOTIFICATION]: "notificationDropDown",
};

const dropDownItemClass: Record<DropDownTypes, string> = {
  [DropDownTypes.MENU]: "dropDown__item",
  [DropDownTypes.NOTIFICATION]: "notificationDropDown__item",
};

export const DropDown: FC<DropDownProps> = ({ type, items, onBlur, className = "" }, index) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target as HTMLElement) && ref.current.parentElement !== e.target) {
        e.stopPropagation();
        onBlur?.();
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [onBlur]);

  const dropDownItems = items.map((dropDownItem: DropDownItemWithLink | DropDownItemWithOnClick) => {
    if ("link" in dropDownItem) {
      const { link, text, icon } = dropDownItem;
      return (
        <Link to={link} key={`${text}-${index}`} className={dropDownItemClass[type]}>
          {icon && <div className={`${dropDownItemClass[type]}__icon`}>{icon}</div>}
          <div className={`${dropDownItemClass[type]}__text`}>{text}</div>
        </Link>
      );
    } else {
      const { onClick, text, icon } = dropDownItem;
      return (
        <div key={`${text}-${index}`} className={dropDownItemClass[type]} onClick={onClick}>
          {icon && <div className={`${dropDownItemClass[type]}_icon`}>{icon}</div>}
          <div className={`${dropDownItemClass[type]}_text`}>{text}</div>
        </div>
      );
    }
  });

  return (
    <div className={cn(dropDownWrapperClass[type], className)} ref={ref}>
      {dropDownItems}
    </div>
  );
};
