import React, { useEffect } from "react";
import cn from "classnames";

import { useAppDispatch } from "@hooks/redux";
import { deleteToast } from "@redux/toast/toast.slice";

import { Icon, IconSize } from "./Icon/Icon";
import { IconTypes } from "./Icon/IconsLib";

export interface Toast {
  id: string;
  message: string;
  type: TypeToast;
}

export enum TypeToast {
  ERROR = "error",
  INFO = "info",
  SUCCESS = "success",
  WARNING = "warning",
}

export const TypeToastMap: { [key in TypeToast]: IconTypes } = {
  [TypeToast.SUCCESS]: IconTypes.CHECK,
  [TypeToast.ERROR]: IconTypes.CLOSE_CIRCLE,
  [TypeToast.WARNING]: IconTypes.INFO,
  [TypeToast.INFO]: IconTypes.INFO,
};

interface ToastProps {
  toast: Toast;
}

const TIMEOUT_DELETE_TOAST = 10000;

export const Toast: React.FC<ToastProps> = ({ toast: { id, message, type } }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    setTimeout(() => {
      dispatch(deleteToast(id));
    }, TIMEOUT_DELETE_TOAST);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelete = () => dispatch(deleteToast(id));

  return (
    <div className="toast">
      <Icon icon={TypeToastMap[type]} size={IconSize.SMALL} className={cn("toast__icon", type)} />
      <p className="toast__message">{message}</p>
      <Icon icon={IconTypes.CLOSE} size={IconSize.SMALL} className={cn("toast__icon", "pointer")} onClick={handleDelete} />
    </div>
  );
};
